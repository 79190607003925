import { useMemo } from 'react';
import moment from 'moment';

/**
 * Test if this time range is recent
 *
 * This is used to determine if NRT data should be used.
 * @param {string} startDate range start
 * @param {string} endDate range end
 * @param {number} firstDayThreshold the day after which the date is considered recent
 */
export const useIsRecentDay = (startDate, endDate, firstDayThreshold = 3) => {
  const isRecentDay = useMemo(() => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const recentThreshold = moment().subtract(firstDayThreshold, 'days');
    return startDateMoment.isAfter(recentThreshold) && endDateMoment.isAfter(recentThreshold);
  }, [startDate, endDate, firstDayThreshold]);
  return isRecentDay;
};

/**
 * Get the number of days in the time range
 *
 * @param {string} startDate start date
 * @param {string} endDate end date
 * @returns number
 */
export const useDateCount = (startDate, endDate) => {
  const dateCount = useMemo(() => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const diff = endDateMoment.diff(startDateMoment, 'days');
    return (diff < 0 ? 0 : diff) + 1;
  }, [startDate, endDate]);
  return dateCount;
};

export const getTooltipForMdsIndirectColumn = (startDate, endDate) => {
  const currentDate = new Date();
  const endOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const july2024 = new Date('2024-07-01');
  if (end < july2024 || start > endOfPreviousMonth) {
    return 'Data is not available in selected date range';
  }

  const minDate = start > july2024 ? start : july2024;
  const maxDate = end < endOfPreviousMonth ? end : endOfPreviousMonth;

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return `Data is available from ${formatDate(minDate)} to ${formatDate(maxDate)}`;
};
