export const serverContext = {
  environment: 'Development',
  logLevel: '0',
  logEnableConsoleOutput: 'false',
  enableAppInsights: 'true',
  appInsightsInstrumentationKey: '0f0c0c77-7608-4f98-a127-f91c2d1bc5e0',
  baseUrls: {
    cosman: 'https://cosmanapi-dev.azurewebsites.net',
    cosmanEU: 'https://cosmanapieu-dev.azurewebsites.net',
    efficiencyEngine: 'https://efficiencyengineapistaging.azurewebsites.net',
    cogsInsight: 'https://cogsinsight-dev.azurewebsites.net',
    cosmanCopilot: 'https://cosmancopilot.azurewebsites.net',
    pipelineStudio: 'https://integratedoptimizationplatform-dev.azurewebsites.net/',
    blueshiftBuildout: 'https://blueshiftautomationapi-staging.azurewebsites.net',
    coldStorage: 'https://cosmancoldstorageapi-feature.azurewebsites.net',
  },
  auth: {
    clientId: '82c9d771-e810-4eed-b493-57b8b07ee575',
    authority: 'https://login.microsoftonline.com/common',
    scopes: ['api://0f830cea-7057-4b09-9d4b-790a05490487/cosman_service'],
  },
};
