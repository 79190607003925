import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/cosman/mock-policy';
import { BaseService } from '../base-service';

export class ColdStorageService extends BaseService {
  constructor() {
    super({ serviceName: 'ColdStorage' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }
}

export const coldStorageService = new ColdStorageService();
