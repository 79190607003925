import _ from 'underscore';
import { colors } from '@cosman/config';

const getColor = (chartColorMapping, name) => {
  if (!_.isEmpty(chartColorMapping) && chartColorMapping[name]) {
    return chartColorMapping[name];
  }
  const color = colors.getPieChartColor(_.size(chartColorMapping));
  // eslint-disable-next-line no-param-reassign
  chartColorMapping[name] = color;
  return color;
};

export const circleChartDataConverter = (chartData, key = 'realizedCumulativeCost', { chartColorMapping = {}, title = '', unit = '$' }) => {
  if (_.isEmpty(chartData)) {
    return { title };
  }
  const mappedColors = [];
  const data = _.map(chartData, (series, seriesName) => {
    return {
      name: seriesName,
      value: series[key],
    };
  }).sort((a, b) => b.value - a.value);

  _.map(data, (series) => {
    const color = getColor(chartColorMapping, series.name);
    mappedColors.push(color);
  });

  return {
    title,
    option: {
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 0,
        top: 'middle',
        itemHeight: 10,
        itemWidth: 12,
        textStyle: {
          fontSize: 10,
          lineHeight: 10,
          overflow: 'break',
          width: 120,
        },
      },
      colors: mappedColors,
      series: [
        {
          right: 150,
          name: unit,
          data,
        },
      ],
    },
  };
};
